import { NavParams, ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ordenes',
  templateUrl: './ordenes.page.html',
  styleUrls: ['./ordenes.page.scss'],
})
export class OrdenesPage implements OnInit {
  public ordenes: any;
  automaticClose = false;
  constructor(
    private nav: NavParams,
    private modalCtrl: ModalController,
  ) {

    this.ordenes = nav.get('ordenes');
    this.ordenes[0].open = true;
    console.log(this.ordenes);
   }


  ngOnInit() {
  }




  async closeModal() {
    await this.modalCtrl.dismiss({
      dismissed: true,
      saved: false,
    });
  }
  toggleSection(i) {

    this.ordenes[i].open = !this.ordenes[i].open;
    if(this.automaticClose && this.ordenes[i].open){
      this.ordenes.filter((item, itemIndex)=> itemIndex != i).map(item => item.open = false);
    }

  }


  async enviarorden( order: String ) {
    // console.log(this.pRef['rut_chileno']); 

        await this.modalCtrl.dismiss({
          dismissed: true,
          order,
        });
      
    

  }





}
