import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-calificacion',
  templateUrl: './calificacion.page.html',
  styleUrls: ['./calificacion.page.scss'],
})
export class CalificacionPage implements OnInit {


  @Input() rating: number;

  @Output() ratingChange: EventEmitter<number> = new EventEmitter();;

  public shopper: string;
  public id: string;
  public error: boolean;

  constructor(
    private nav: NavParams,
    private modalCtrl: ModalController,
    private _orderService: OrderService,

  ) {
    this.shopper = nav.get('shopper');
    this.id = nav.get('id');
    this.error = false;
  }

  ngOnInit() {
  }

  rate(index: number) {
    // function used to change the value of our rating 
    // triggered when user, clicks a star to change the rating
    this.rating = index;
    this.ratingChange.emit(this.rating);
  }

  getColor(index: number) {
    /* function to return the color of a star based on what
     index it is. All stars greater than the index are assigned
     a grey color , while those equal or less than the rating are
     assigned a color depending on the rating. Using the following criteria:
  
          1-2 stars: red
          3 stars  : yellow
          4-5 stars: green 
    */

    if (this.isAboveRating(index)) {
      return COLORS.GREY;
    }
    switch (this.rating) {
      case 1:
      case 2:
        return COLORS.RED;
      case 3:
        return COLORS.YELLOW;
      case 4:
      case 5:
        return COLORS.GREEN;
      default:
        COLORS.GREY
    }
  }

  isAboveRating(index: number): boolean {
    // returns whether or not the selected index is above ,the current rating
    // function is called from the getColor function.
    return index > this.rating;
  }





  async closeModal() {
    await this.modalCtrl.dismiss({
      dismissed: true,
      saved: false,
    });
  }

  async save() {
    await this.modalCtrl.dismiss({
      dismissed: true,
      saved: false,
    });
  }



  async calificar() {
    if (this.id !== undefined) {
      if (this.rating > 0) {

        console.log(this.rating);
        await this._orderService
          .evaluarEnvio(
            this.id,
            this.rating,

          )
          .then(() => {
            this.error = false;
            this.save();
          }).catch(()=> {
            this.error = true;

          });
        }




    }

  }
}


enum COLORS {
  GREY = "#E0E0E0",
  GREEN = "#76FF03",
  YELLOW = "#FFCA28",
  RED = "#DD2C00"
}