import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { RutService } from 'rut-chileno' 

@Component({
  selector: 'app-modal-confirmation',
  templateUrl: './modal-confirmation.page.html',
  styleUrls: ['./modal-confirmation.page.scss'],
})
export class ModalConfirmationPage implements OnInit {
  public rut_emiter: string;
  rut = ''
  msjError = ''
  title = 'use-rut';
  private rutComprador: string;
  private validateRut: boolean;
  public spanRut: boolean;

  @ViewChild('pRef', {static: false}) pRef: ElementRef;

  constructor(
    private nav: NavParams,
    private modalCtrl: ModalController,
    private rutService: RutService
  ) {
    this.rutComprador = nav.get('track');
    // true cuando el rut NO es valido
    // false cuando es el rut SI es valdo
    this.validateRut = true;
    this.spanRut = false;
  }

  ngOnInit() {
    // console.log(this.rutComprador);
  }

  ngAfterViewInit() {
    
  }

  async closeModal() {
    await this.modalCtrl.dismiss({
      dismissed: true,
      saved: false,
    });
  }


  async validar() {
    // console.log(this.pRef['rut_chileno']); 
    this.spanRut = false;
    let out4_rut = this.rutService.validaRUT(this.pRef['rut_chileno']);
    // console.log(out4_rut);

    this.validateRut = out4_rut

    if (!this.validateRut){
      if(this.rut === this.rutComprador){
        await this.modalCtrl.dismiss({
          dismissed: true,
          saved: true,
        });
      }else{
        this.spanRut = true;
      }
    }

  }
  getRut(rut: string): void {
    this.rut = rut;
    this.validateRut = true
  }

}
