import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-modal-info',
  templateUrl: './modal-info.page.html',
  styleUrls: ['./modal-info.page.scss'],
})
export class ModalInfoPage implements OnInit {

  constructor(
    private nav: NavParams,
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
  }



  async closeModal() {
    await this.modalCtrl.dismiss({
      dismissed: true,
      saved: false,
    });
  }



}
