import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from './user.service';
import { environment } from '../../environments/environment';
import { TrackInfo } from '../model/trackInfo';
import { Observable, of } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

@Injectable({
    providedIn: 'root'
})
export class OrderService {

    constructor(private http: HttpClient) {
    }

    list() {
        let httpOptions = {
            headers: { "Content-Type": "application/json" }
        }
        return this.http.get(environment.apiurl + '/api/orders/', httpOptions).toPromise();
    }

    stop_order(id: string) {
        let httpOptions = {
            headers: { "Content-Type": "application/json" }
        }
        return this.http.get(environment.apiurl + '/api/bags/' + id + '/stop/', httpOptions).toPromise();
    }

    start_order(id: string) {
        let httpOptions = {
            headers: { "Content-Type": "application/json" }
        }
        return this.http.get(environment.apiurl + '/api/bags/' + id + '/start/', httpOptions).toPromise();
    }

    save_signature(id: string, image: string) {
        let httpOptions = {
            headers: { "Content-Type": "application/json" }
        }
        return this.http.post(environment.apiurl + '/api/bags/' + id + '/signature/', JSON.stringify({ 'signature': image }), httpOptions).toPromise();
    }

    save_problem(id: string, problem: string) {
        let httpOptions = {
            headers: { "Content-Type": "application/json" }
        }
        return this.http.post(environment.apiurl + '/api/bags/' + id + '/problem/', JSON.stringify({ 'problem': problem }), httpOptions).toPromise();
    }

    // send a POST request to the API to create a new blog post
    create(post) {
        let httpOptions = {
            headers: { "Content-Type": "application/json" }
        }
        return this.http.post(environment.apiurl + '/api/orders/', JSON.stringify(post), httpOptions).toPromise();
    }

    track(code: string): Observable<TrackInfo> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };
        return this.http.get<TrackInfo>(`${environment.apiurl}/api/orders/${code}/track2/`, httpOptions).pipe(
            map(heroes => heroes),
            tap(_ => this.log(`fetched hero id=`)),
            catchError(this.handleError<TrackInfo>(`getHero id=`))
        );
    }
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    /** Log a HeroService message with the MessageService */
    private log(message: string) {
    }
    // getHero(id: number): Observable<Hero> {
    //     const url = `${this.heroesUrl}/${id}`;
    //     return this.http.get<Hero>(url).pipe(
    //         tap(_ => this.log(`fetched hero id=${id}`)),
    //         catchError(this.handleError<Hero>(`getHero id=${id}`))
    //     );
    // }

    update_address(id: string, address: string, lat: number, lng: number, infoComplement: string) {
        if (id !== undefined) {
            const httpOptions = {
                headers: { "Content-Type": "application/json" },
            };
            return this.http
                .put(
                    environment.apiurl + "/api/orders/putaddress/",
                    JSON.stringify({
                        id,
                        address,
                        lat,
                        lng,
                        infoComplement
                    }),
                    httpOptions
                )
                .toPromise();
        }
    }



    check(bag_id: string) {
        if (bag_id !== undefined) {
            const httpOptions = {
                headers: { "Content-Type": "application/json" },
            };
            const new_bag_id =  bag_id;
            return this.http
                .get(
                    environment.apiurl + `/api/orders/${new_bag_id}/changeaddress/`, httpOptions
                )
                .toPromise();
        }
    }



    trackByEmail(email: string) {
        if (email !== undefined) {
            const httpOptions = {
                headers: { "Content-Type": "application/json" },
            };
            return this.http
                .get(
                    environment.apiurl + `/api/orders/${email}/track_email/`, httpOptions
                )
                .toPromise();
        }



    }

    evaluarEnvio(id: string, evaluation: number) {
        if (id !== undefined) {
            const httpOptions = {
                headers: { "Content-Type": "application/json" },
            };
            return this.http
                .put(
                    environment.apiurl + `/api/orders/putcalificacion/`,
                    JSON.stringify({
                        "id": id,
                        "calificacion": evaluation,
                    }),
                    httpOptions
                )
                .toPromise();
        }
    }



}