import { OrdenesPage } from './modal/ordenes/ordenes.page';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule }   from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { ModalInfoPage } from './modal/modal-info/modal-info.page';
import { ModalConfirmationPage } from './modal/modal-confirmation/modal-confirmation.page';
import { CalificacionPage } from './modal/calificacion/calificacion.page';

import { RutModule } from 'rut-chileno';
import { ModalPhotoPage } from './modal/modal-photo/modal-photo.page';
@NgModule({
  declarations: [
    AppComponent,
    ModalInfoPage,
    ModalConfirmationPage,
    CalificacionPage,
    OrdenesPage,
    ModalPhotoPage
  ],
  entryComponents: [
    ModalInfoPage,
    ModalConfirmationPage,
    CalificacionPage,
    OrdenesPage,
    ModalPhotoPage
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    HttpClientModule,
    // IonicStorageModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    RutModule
     
  ],

    
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],

  bootstrap: [AppComponent]
})
export class AppModule {}
